import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'

// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css'
// Bootstrap Bundle JS
import 'bootstrap/dist/js/bootstrap.bundle.min'

// Toast
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

//Table
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import { BrowserRouter } from 'react-router-dom'

import { store } from './redux'
import { Provider } from 'react-redux'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<Provider store={store}>
		<React.StrictMode>
			<BrowserRouter>
				<App />
				<ToastContainer limit={3} />
			</BrowserRouter>
		</React.StrictMode>
	</Provider>
)
