import React from 'react'
import DashboardHeader from '../../components/dashboardHeader'

const History = () => {
	return (
		<div className='dashboard-body'>
			<div className='dashboard-content'>
				<DashboardHeader pageTitle={'History'} />
				<div className='page-section'>
					<div className='table-card'>
						<div className='table-head'>
							<h1 className='table-title'>Transaction History</h1>
						</div>
						<div className='table-scroll'>
							<tbody className='table-body'>
								<tr>
									<td className='text-center'>No Data Available</td>
								</tr>
							</tbody>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default History
