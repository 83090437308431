import { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import SignIn from '../pages/SignIn'
import LogIn from '../pages/LogIn'
import DashboardLayout from '../pages/DashboardLayout'
import Home from '../pages/Home'
import FinancialProducts from '../pages/FinancialProducts'
import History from '../pages/History'

const Routing = () => {
	return (
		<Suspense>
			<Routes>
				<Route path='/login' element={<LogIn />} />
				<Route path='/signin' element={<SignIn />} />
				<Route path='/*' element={<DashboardLayout />}>
					<Route path='' element={<Navigate to='/home' replace={true} />} />
					<Route path='home' element={<Home />} />
					<Route path='financial-products' element={<FinancialProducts />} />
					<Route path='history' element={<History />} />
				</Route>
			</Routes>
		</Suspense>
	)
}

export default Routing
