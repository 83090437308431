import React, { useEffect, useState } from 'react'
import './style.css'

import { Spinner } from 'react-bootstrap'

import axios from 'axios'

import { useNavigate } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { updateIsLoggedIn, updateLoginInfo } from '../../redux/slices/main'
import { showToast } from '../../utils'

const LogIn = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const isLoggedIn = useSelector(state => state.main.isLoggedIn)
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')

	console.log(isLoggedIn)

	useEffect(() => {
		if (isLoggedIn) {
			navigate('/')
		}
	}, [isLoggedIn])

	function handleLogin(event = '') {
		setIsLoading(true)
		if (event !== '') event.preventDefault()

		const data = {
			user_name: username,
			password,
		}

		axios
			.post('http://54.151.224.118:4000/wow/admin/user/login', data, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then(response => {
				console.log(response)
				if (response.data.status === false) {
					alert(response.data.message)
				} else {
					console.log(response)
					const loginData = {
						token: response.data.payload.token,
						_id: response.data.payload.user._id,
						user_name: response.data.payload.user.user_name,
					}
					localStorage.setItem('jwtToken', response.data.payload.token)
					dispatch(updateLoginInfo(loginData))
					dispatch(updateIsLoggedIn(true))
					setIsLoading(false)
				}
			})
			.catch(err => {
				console.log(err)
				if (err?.message) {
					showToast('error', err.message)
				} else {
					showToast('error', err.response.data.message)
				}
			})
		setIsLoading(false)
	}

	return (
		<div className='login'>
			<div className='login-card'>
				<p className='card-title'>login</p>
				<form className='login-form' onSubmit={handleLogin}>
					<div className='input-field'>
						<label htmlFor='username'>Username</label>
						<input
							placeholder='enter username'
							type='text'
							name='username'
							id='username'
							required
							autoComplete='off'
							value={username}
							onChange={e => setUsername(e.target.value)}
						/>
					</div>
					<div className='input-field'>
						<label htmlFor='password'>Password</label>
						<input
							placeholder='enter password'
							type='password'
							name='password'
							id='password'
							required
							autoComplete='off'
							value={password}
							onChange={e => setPassword(e.target.value)}
						/>
					</div>
					<button className='login-button' type='submit'>
						{isLoading ? <Spinner animation='border' variant='light' /> : <>login</>}
					</button>
				</form>
			</div>
		</div>
	)
}

export default LogIn
