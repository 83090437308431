import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import Sidebar from '../../components/sidebar'
import './style.css'

const DashboardLayout = () => {
	const isLoggedIn = useSelector(state => state.main.isLoggedIn)

	console.log(isLoggedIn)

	if (!isLoggedIn) {
		return <Navigate to={'/login'} />
	} else {
		return (
			<div className='dashboard-layout'>
				<Sidebar />
				<Outlet />
			</div>
		)
	}
}

export default DashboardLayout
