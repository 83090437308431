import React, { Children } from 'react'
import { X } from 'react-bootstrap-icons'
import './style.css'

const Modal = ({ isOpen, onClose, children }) => {
	return (
		<>
			{isOpen && (
				<div className='cmodal-overlay'>
					<div className='cmodal-content'>
						<button className='close-button' onClick={onClose}>
							<X color='black' size={45} />
						</button>
						{children}
					</div>
				</div>
			)}
		</>
	)
}

export default Modal
