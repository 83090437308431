import { toast } from 'react-toastify'
import axios from 'axios'

import baseURL from '../baseURL'

//Date formate

export const formattedDate = date => {
	const fDate = new Date(date)
	return fDate.toLocaleString()
}

// TABLE HEADERS

export const FPDataHeader = [
	'product name',
	'currency',
	'term',
	'Annual IR',
	'investment limit',
	'min. purchase',
	'start time',
	'end time',
	'status',
	'actions',
]

// TOAST

export const showToast = (type, message) => {
	const customID = 1
	switch (type) {
		case 'error': {
			toast.error(message, {
				position: 'bottom-left',
				autoClose: 3000,
				theme: 'colored',
				toastId: customID,
				pauseOnHover: true,
			})
		}
		case 'success': {
			toast.success(message, {
				position: 'bottom-left',
				autoClose: 3000,
				theme: 'colored',
				toastId: customID,
				pauseOnHover: true,
			})
		}
		case 'info': {
			toast.info(message, {
				position: 'bottom-left',
				autoClose: 3000,
				theme: 'colored',
				toastId: customID,
				pauseOnHover: true,
			})
		}
	}
}

//AUTH TOKEN

const getJWTToken = () => {
	return localStorage.getItem('jwtToken')
}

// API CALL

const axiosInstance = axios.create({
	baseURL: baseURL,
	headers: {
		'Content-Type': 'application/json',
	},
})

axiosInstance.interceptors.request.use(config => {
	const token = getJWTToken()
	if (token) {
		config.headers['token'] = `${token}`
	}
	return config
})

export default axiosInstance
