import { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './style.css'
import { House, Power } from 'react-bootstrap-icons'
import { Database } from 'react-bootstrap-icons'
import { ListCheck } from 'react-bootstrap-icons'
import axiosInstance from '../../utils'
import baseURL from '../../baseURL'
import { useDispatch } from 'react-redux'
import { updateIsLoggedIn, updateProducts } from '../../redux/slices/main'
import Modal from '../modal'

const sidebarNavItems = [
	{
		display: 'Home',
		icon: <House color='white' size={30} />,
		to: '/',
		section: 'home',
	},
	{
		display: 'Financial Products',
		icon: <Database color='white' size={30} />,
		to: '/financial-products',
		section: 'financial-products',
	},
	{
		display: 'History',
		icon: <ListCheck color='white' size={30} />,
		to: '/history',
		section: 'history',
	},
]

const Sidebar = () => {
	const dispatch = useDispatch()

	const [showModal, setShowModal] = useState(false)

	const showLogoutModal = () => {
		setShowModal(true)
	}

	const hideLogoutModal = () => {
		setShowModal(false)
	}

	const [activeIndex, setActiveIndex] = useState(0)
	const location = useLocation()

	// admin data fetch
	useEffect(() => {
		axiosInstance
			.get(`${baseURL}products/fetch-product`)
			.then(res => {
				console.log(res)
				dispatch(updateProducts(res.data.products))
			})
			.catch(err => {
				console.log(err)
			})
	}, [])

	// change active index
	useEffect(() => {
		const curPath = window.location.pathname.split('/')[1]
		console.log(window.location.href)
		const activeItem = sidebarNavItems.findIndex(item => item.section === curPath)
		console.log(curPath)
		console.log(activeItem)
		setActiveIndex(curPath === 'home' ? 0 : activeItem)
	}, [location])

	const onLogout = () => {
		localStorage.setItem('jwtToken', '')
		dispatch(updateIsLoggedIn(false))
	}

	return (
		<div className='sidebar'>
			<div className='sidebar__logo'>wow earn</div>
			<div className='sidebar__menu'>
				{sidebarNavItems.map((item, index) => (
					<Link to={item.to} key={index}>
						<div
							className={`sidebar__menu__item ${activeIndex === index ? 'active' : ''}`}
						>
							<div className='sidebar__menu__item__icon'>{item.icon}</div>
							<div className='sidebar__menu__item__text'>{item.display}</div>
						</div>
					</Link>
				))}
				<button onClick={showLogoutModal} className='logout-button'>
					<Power color='white' size={30} />
				</button>
			</div>

			<Modal isOpen={showModal} onClose={hideLogoutModal}>
				<div className='logout-modal-content'>
					<h1>are you sure you want to logout?</h1>
					<button onClick={onLogout} className='modal-button'>
						logout
					</button>
				</div>
			</Modal>
		</div>
	)
}

export default Sidebar
