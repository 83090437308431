import React, { useEffect, useState } from 'react'
import axiosInstance, { FPDataHeader, formattedDate, showToast } from '../../utils'
import { useSelector, useDispatch } from 'react-redux'
import { Trash } from 'react-bootstrap-icons'

import './style.css'
import DashboardHeader from '../../components/dashboardHeader'
import Modal from '../../components/modal'
import baseURL from '../../baseURL'
import { Plus } from 'react-bootstrap-icons'
import { updateProducts } from '../../redux/slices/main'

const FinancialProducts = () => {
	const dispatch = useDispatch()

	const FPData = useSelector(state => state.main.products)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [showEditModal, setShowEditModal] = useState(false)
	const [showCreateModal, setShowCreateModal] = useState(false)

	const [productName, setProductName] = useState('')
	const [currency, setCurrency] = useState('')
	const [term, setTerm] = useState('')
	const [monthlyIR, setMonthlyIR] = useState('')
	const [purchaseLimit, setPurchaseLimit] = useState('')
	const [minPurchase, setMinPurchase] = useState('')
	const [limitPerUser, setLimitPerUser] = useState('')
	const [status, setStatus] = useState('')
	const [startTime, setStartTime] = useState('')
	const [endTime, setEndTime] = useState('')

	const [toDelete, setToDelete] = useState()
	const [toEdit, setToEdit] = useState()
	const [updatedProduct, setUpdatedProduct] = useState({})

	const handleSelectCurrency = e => {
		setCurrency(e.target.value)
	}

	const onShowDeleteModal = (id, name) => {
		setToDelete([id, name])
		setShowDeleteModal(true)
	}

	const onShowEditModal = data => {
		console.log(data)
		setToEdit(data)
		setShowEditModal(true)
	}

	const onUpdateToEdit = (key, value) => {
		setToEdit(prevState => ({
			...prevState,
			[key]: value,
		}))
		setUpdatedProduct(prevState => ({
			...prevState,
			[key]: value,
		}))
	}

	const onShowCreateModal = () => {
		setShowCreateModal(true)
	}

	const onHideDeleteModal = () => {
		setShowDeleteModal(false)
	}

	const onHideEditModal = () => {
		setShowEditModal(false)
	}

	const onHideCreateModal = () => {
		setShowCreateModal(false)
	}

	const getStatus = (startdate, enddate) => {
		const sDate = new Date(startdate)
		const eDate = new Date(enddate)

		const cDate = new Date()

		if (cDate < sDate) {
			return 'Pending'
		} else if (cDate > eDate) {
			return 'Ended'
		} else {
			return 'Ongoing'
		}
	}

	const handleDeleteProduct = () => {
		console.log(toDelete)
		axiosInstance
			.delete(`${baseURL}products/delete-product/${toDelete[0]}`)
			.then(res => {
				console.log(res)
				showToast('success', res.data.meassge)
				const up = FPData.filter(data => data._id !== toDelete[0])
				dispatch(updateProducts(up))
				setShowDeleteModal(false)
			})
			.catch(err => {
				console.log(err)
				showToast('error', err.response.data.message)
			})
	}

	function handleEditProduct(event = '') {
		if (event !== '') event.preventDefault()

		// if()

		console.log(updatedProduct)

		axiosInstance
			.post(`${baseURL}products/update-product/${toEdit._id}`, { ...updatedProduct })
			.then(res => {
				console.log(res)
				const update = res.data.updatedProduct
				let up = [...FPData]
				console.log(update)
				console.log(up)
				let index = up.findIndex(item => item._id === toEdit._id)
				console.log(index)
				up[index] = update
				console.log(up)
				dispatch(updateProducts(up))
				showToast('success', res.data.message)
				setShowEditModal(false)
			})
			.catch(err => {
				console.log(err)
				showToast('error', err.response.data.message)
			})
	}

	function handleCreateProduct(event = '') {
		if (event != '') event.preventDefault()
		const data = {
			productName,
			totalPurchaseLimit: purchaseLimit,
			purchaseRestrictedCurrency: 'USDT',
			term,
			annualizedInterestRate: monthlyIR,
			minimumPurchaseAmount: minPurchase,
			eventTiming: {
				startTime,
				endTime,
			},
		}

		console.log(data)

		axiosInstance
			.post(`${baseURL}products/create-product`, data)
			.then(res => {
				console.log(res)
				showToast('success', res.data.message)
				setShowCreateModal(false)
				const up = [...FPData, res.data.product]
				dispatch(updateProducts(up))
				setStartTime('')
				setEndTime('')
			})
			.catch(err => {
				console.log(err)
				showToast('error', err.message)
			})
	}

	return (
		<div className='dashboard-body'>
			<div className='dashboard-content'>
				<DashboardHeader pageTitle={'Financial Products'} />
				<div className='page-section'>
					<div className='table-card'>
						<div className='table-head'>
							<h1 className='table-title'>Fincancial Products</h1>
							<button onClick={() => onShowCreateModal()} className='create-button'>
								<Plus color='#171717' size={30} />
								Create
							</button>
						</div>
						<div className='table-scroll'>
							<table className='table'>
								<thead>
									<tr>
										{FPDataHeader.map((header, index) => (
											<th className='table-header' key={index}>
												{header}
											</th>
										))}
									</tr>
								</thead>
								{FPData?.length && FPData?.length > 0 ? (
									<tbody className='table-body'>
										{FPData.map((data, index) => (
											<tr key={index}>
												<td>{data.productName}</td>
												<td>{data.purchaseRestrictedCurrency}</td>
												<td>{data.term} days</td>
												<td>{data.annualizedInterestRate}%</td>
												<td>{data.totalPurchaseLimit}</td>
												<td>{data.minimumPurchaseAmount}</td>
												<td>{formattedDate(data.eventTiming.startTime)}</td>
												<td>{formattedDate(data.eventTiming.endTime)}</td>
												<td>
													{getStatus(
														data.eventTiming.startTime,
														data.eventTiming.endTime
													)}
												</td>
												<td>
													<div className='action-row'>
														<button
															onClick={() => onShowEditModal(data)}
															className='edit-button'
														>
															Edit
														</button>
														<button
															onClick={() =>
																onShowDeleteModal(data._id, data.productName)
															}
															className='delete-button'
														>
															<Trash color='white' />
														</button>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								) : (
									<tbody className='table-body'>
										<tr>
											<td colSpan={FPDataHeader.length} className='text-center'>
												No Data Available
											</td>
										</tr>
									</tbody>
								)}
							</table>
						</div>
					</div>
					{showEditModal && (
						<Modal onClose={onHideEditModal} isOpen={showEditModal}>
							<div className='create-modal'>
								<h1 className='create-title'>Edit {toEdit.productName}</h1>
								<form className='create-form' onSubmit={handleEditProduct}>
									<div className='d-flex gap-3'>
										<div className='input-field'>
											<label htmlFor='productName'>Product Name</label>
											<input
												placeholder={toEdit.productName}
												type='text'
												name='productName'
												id='productName'
												autoComplete='off'
												value={toEdit.productName}
												onChange={e => onUpdateToEdit('productName', e.target.value)}
											/>
										</div>
										<div className='input-field'>
											<label htmlFor='currency'>Currency</label>
											<input
												placeholder='USDT'
												disabled
												type='text'
												name='productName'
												id='productName'
												autoComplete='off'
												value={'USDT'}
												// onChange={e => setProductName(e.target.value)}
											/>
										</div>
									</div>
									<div className='d-flex gap-3'>
										<div className='input-field'>
											<label htmlFor='term'>Term (duration)</label>
											<input
												placeholder='Enter Number Of Days'
												type='text'
												name='term'
												id='term'
												autoComplete='off'
												value={toEdit.term}
												onChange={e => onUpdateToEdit('term', e.target.value)}
											/>
										</div>
										<div className='input-field'>
											<label htmlFor='minPrcAmount'>Min. Purchase Amount</label>
											<input
												placeholder='Enter Purchase Limit'
												type='text'
												name='minPrcAmount'
												id='minPrcAmount'
												autoComplete='off'
												value={toEdit.minimumPurchasePrice}
												onChange={e =>
													onUpdateToEdit('minimumPurchaseAmount', e.target.value)
												}
											/>
										</div>
									</div>
									<div className='d-flex gap-3'>
										<div className='input-field'>
											<label htmlFor='monthlyIR'>Annualised Interest Rate</label>
											<input
												placeholder='Anual Percentage Return'
												type='text'
												name='monthlyIR'
												id='monthlyIR'
												autoComplete='off'
												value={toEdit.annualizedInterestRate}
												onChange={e =>
													onUpdateToEdit('annualizedInterestRate', e.target.value)
												}
											/>
										</div>
										<div className='input-field'>
											<label htmlFor='totalPurchaseLimit'>Total Purchase Limit</label>
											<input
												placeholder='Enter Purchase Limit'
												type='text'
												name='totalPurchaseLimit'
												id='totalPurchaseLimit'
												autoComplete='off'
												value={toEdit.totalPurchaseLimit}
												onChange={e =>
													onUpdateToEdit('totalPurchaseLimit', e.target.value)
												}
											/>
										</div>
									</div>
									{/* <div className='d-flex gap-3'>
										
										<div className='input-field'>
											<label htmlFor='perUserLimit'>Per User Investment limit</label>
											<input
												placeholder='Enter User Limit'
												type='text'
												name='perUserLimit'
												id='perUserLimit'
												required
												autoComplete='off'
												value={toEdit.perUserInvestmentLimit}
												onChange={e =>
													onUpdateToEdit('perUserInvestmentLimit', e.target.value)
												}
											/>
										</div>
									</div> */}
									<div className='d-flex gap-3 align-items-end'>
										<div className='input-field'>
											<label htmlFor='startTime'>
												Start Date ({formattedDate(toEdit.eventTiming.startTime)})
											</label>
											<input
												type='date'
												name='startTime'
												id='startTime'
												autoComplete='off'
												value={startTime}
												onChange={e => setStartTime(e.target.value)}
											/>
										</div>
										<div className='input-field'>
											<label htmlFor='endTime'>
												End Date ({formattedDate(toEdit.eventTiming.endTime)})
											</label>
											<input
												type='date'
												name='endTime'
												id='endTime'
												autoComplete='off'
												value={endTime}
												onChange={e => setEndTime(e.target.value)}
											/>
										</div>
									</div>
									<button type='submit' className='create-form-button'>
										Edit
									</button>
								</form>
							</div>
						</Modal>
					)}
					{showDeleteModal && (
						<Modal onClose={onHideDeleteModal} isOpen={showDeleteModal}>
							<div className='logout-modal-content'>
								<h1>are you sure you want to delete {toDelete[1]}?</h1>
								<button onClick={() => handleDeleteProduct()} className='modal-button'>
									Delete
								</button>
							</div>
						</Modal>
					)}

					{showCreateModal && (
						<Modal onClose={onHideCreateModal} isOpen={showCreateModal}>
							<div className='create-modal'>
								<h1 className='create-title'>Create Financial Product</h1>
								<form className='create-form' onSubmit={handleCreateProduct}>
									<div className='d-flex gap-3'>
										<div className='input-field'>
											<label htmlFor='productName'>Product Name</label>
											<input
												placeholder='Enter Product Name'
												type='text'
												name='productName'
												id='productName'
												required
												autoComplete='off'
												value={productName}
												onChange={e => setProductName(e.target.value)}
											/>
										</div>
										<div className='input-field'>
											<label htmlFor='currency'>Currency</label>
											<input
												placeholder='USDT'
												disabled
												type='text'
												name='productName'
												id='productName'
												autoComplete='off'
												value={'USDT'}
												onChange={e => setProductName(e.target.value)}
											/>
											{/* <select
												id='currency'
												value={currency}
												onChange={handleSelectCurrency}
											>
												<option value={''}>Select Currency ...</option>
												<option value={'ETH'}>ETH</option>
												<option value={'BSC'}>BSC</option>
												<option value={'TRX'}>TRX (Tron)</option>
											</select> */}
										</div>
									</div>
									<div className='d-flex gap-3'>
										<div className='input-field'>
											<label htmlFor='term'>Term (Duration)</label>
											<input
												placeholder='Number of days'
												type='text'
												name='term'
												id='term'
												required
												autoComplete='off'
												value={term}
												onChange={e => setTerm(e.target.value)}
											/>
										</div>
										<div className='input-field'>
											<label htmlFor='minPrcAmount'>Min. Purchase Amount</label>
											<input
												placeholder='Enter Purchase Limit'
												type='text'
												name='minPrcAmount'
												id='minPrcAmount'
												required
												autoComplete='off'
												value={minPurchase}
												onChange={e => setMinPurchase(e.target.value)}
											/>
										</div>
									</div>
									<div className='d-flex gap-3'>
										<div className='input-field'>
											<label htmlFor='monthlyIR'>Annualised Interest Rate</label>
											<input
												placeholder='Anual Percentage Return'
												type='text'
												name='monthlyIR'
												id='monthlyIR'
												required
												autoComplete='off'
												value={monthlyIR}
												onChange={e => setMonthlyIR(e.target.value)}
											/>
										</div>
										<div className='input-field'>
											<label htmlFor='totalPurchaseLimit'>Total Purchase Limit</label>
											<input
												placeholder='Enter Purchase Limit'
												type='text'
												name='totalPurchaseLimit'
												id='totalPurchaseLimit'
												required
												autoComplete='off'
												value={purchaseLimit}
												onChange={e => setPurchaseLimit(e.target.value)}
											/>
										</div>
									</div>
									{/* <div className='d-flex gap-3'>
										<div className='input-field'>
											<label htmlFor='perUserLimit'>Per User Investment limit</label>
											<input
												placeholder='Enter User Limit'
												type='text'
												name='perUserLimit'
												id='perUserLimit'
												required
												autoComplete='off'
												value={limitPerUser}
												onChange={e => setLimitPerUser(e.target.value)}
											/>
										</div>
									</div> */}
									<div className='d-flex gap-3'>
										<div className='input-field'>
											<label htmlFor='startTime'>Start Date</label>
											<input
												placeholder='Select Start Date'
												type='date'
												name='startTime'
												id='startTime'
												required
												autoComplete='off'
												value={startTime}
												onChange={e => setStartTime(e.target.value)}
											/>
										</div>
										<div className='input-field'>
											<label htmlFor='endTime'>End Date</label>
											<input
												placeholder='Select End Date'
												type='date'
												name='endTime'
												id='endTime'
												required
												autoComplete='off'
												value={endTime}
												onChange={e => setEndTime(e.target.value)}
											/>
										</div>
									</div>
									<button type='submit' className='create-form-button'>
										create
									</button>
								</form>
							</div>
						</Modal>
					)}
				</div>
			</div>
		</div>
	)
}

export default FinancialProducts
