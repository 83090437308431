import React, { useState } from 'react'
import './style.css'

const DashboardHeader = ({ pageTitle }) => {
	return (
		<div className='page-header'>
			<h1 className='page-title'>
				<span>{'> '}</span>
				{pageTitle}
			</h1>
		</div>
	)
}

export default DashboardHeader
