import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	isLoggedIn: false,
	_id: '',
	user_name: '',
	token: '',
	products: [],
}

export const mainSlice = createSlice({
	name: 'main',
	initialState,
	reducers: {
		updateIsLoggedIn: (state, action) => {
			state.isLoggedIn = action.payload
		},
		updateLoginInfo: (state, action) => {
			state._id = action.payload._id
			state.user_name = action.payload.user_name
			state.token = action.payload.token
		},
		updateProducts: (state, action) => {
			state.products = action.payload
		},
	},
})

export const { updateIsLoggedIn, updateLoginInfo, updateProducts } = mainSlice.actions

export default mainSlice.reducer
